.dashboard-room-box {
	position: relative;
	width: 100%;
	padding-right: .75rem;
	padding-left: .75rem;

	flex: 0 0 50%;
	max-width: 50%;
}
@media (min-width: 576px) {
	.dashboard-room-box {
		flex: 0 0 25%;
		max-width: 25%;
	}
}
@media (min-width: 768px) {
	.dashboard-room-box {
		flex: 0 0 25%;
		max-width: 25%;
	}
}
@media (min-width: 992px) {
	.dashboard-room-box {
		flex: 0 0 20%;
		max-width: 20%;
	}
}
@media (min-width: 1200px) {
	.dashboard-room-box {
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
}
.dashboard-room-box a {
	text-decoration: none;
}
.dashboard-info-box .row {
	position: relative;
}
.dashboard-info-box .row .col {
	z-index: 1;
}
.dashboard-info-box .col-icon {
	position: absolute;
	right: 0px;
}
.select2 {
	width: 100% !important;
}
.select2-container--default .select2-selection--multiple {
	background-color: #fff;
	border: 1px solid #d1d3e2;
	border-radius: .35rem;
	min-height: calc(1.5em + .75rem + 2px);
	padding: 0rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
	border-color: #bac8f3;
	outline: 0;
	box-shadow: 0 0 0.1rem 0.075rem rgba(78,115,223,.25)
}
.select2-container .select2-search--inline .select2-search__field {
	min-height: 36px
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
	top: initial;
}
.select2-container--default .select2-search--inline .select2-search__field {
	color: #6e707e
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
	background-color: #d4d4d4;
	border: 1px solid #afa8a8;
	color: #131313;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	color: #131313;
}
.select2-dropdown {
	background-color: #efefef;
	border: 1px solid #d1d3e2;
	color: #131313;
}